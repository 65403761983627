import LoginRegisterForm from "../LoginPage/LoginRegistration";

function Market() {
    return (
      <>
     <LoginRegisterForm/>
      </>
    );
  }
  
  export default Market;